import React from 'react'
import CompanyView from './CompanyView'

const CompanyProfile = () => {
  return (
    <div>
      <CompanyView/>
    </div>
  )
}

export default CompanyProfile