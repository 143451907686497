import React from 'react'
import AdminViewHeader from './AdminViewHeader'

const AdminView = () => {
  return (
    <div className='pb-16'>
        <AdminViewHeader/>
    </div>
  )
}

export default AdminView